<template>
  <b-card class="mb-0" no-body>
    <div class="m-2">
      <b-row class="mb-1 m-0">
        <b-col class="d-flex p-0" cols="12" md="6">
          {{ $t('translation.source.title') }}
          <div class="ml-1 switch-group">
            <div class="mr-1">
              {{ $t('translation.source.api') }}
            </div>
            <div>
              <b-form-checkbox v-model="fromInterface" name="translation-source-button" switch />
            </div>
            <div class="ml-1">
              {{ $t('translation.source.interface') }}
            </div>
          </div>
        </b-col>
        <b-col class="d-flex justify-content-end p-0" cols="12">
          <b-button class="d-flex align-items-center mr-2" @click="downloadTranslation">
            <font-awesome-icon class="mr-50" icon="upload" />
            {{ $t('action.export') }}
          </b-button>
          <b-button class="d-flex align-items-center" @click="selectTranslation">
            <font-awesome-icon class="mr-50" icon="download" />
            {{ $t('action.import') }}
          </b-button>
          <input id="translation-import" ref="translationImport" hidden type="file" @change="uploadTranslation">
        </b-col>
      </b-row>
    </div>
    <translations-api-table v-show="!fromInterface" />
    <translations-interface-table v-show="fromInterface" />
  </b-card>
</template>

<script>
import TranslationsInterfaceTable from '@/views/general-management/translations/TranslationsInterfaceTable.vue'
import TranslationsApiTable from '@/views/general-management/translations/TranslationsApiTable.vue'
import { downloadTranslationCSV, uploadTranslationCSV } from '@/request/globalApi/requests/translationRequests'

export default {
  name: 'TranslationsManagement',

  components: {
    TranslationsInterfaceTable,
    TranslationsApiTable,
  },

  data() {
    return {
      fromInterface: true,
    }
  },
  methods: {
    downloadTranslation() {
      downloadTranslationCSV()
    },
    selectTranslation() {
      document.getElementById('translation-import').click()
    },
    uploadTranslation(event) {
      if (event.target.files.length) {
        const data = new FormData()
        data.append('csv_file', event.target.files[0])

        uploadTranslationCSV(data).finally(() => {
          this.$router.go()
        })
      }
    },
  },
}
</script>

<style>
.switch-group {
  display: inline-flex;
}
</style>
