<template>
  <b-card no-body class="mb-0">
    <div class="m-2">
      <b-row class="pb-2">
        <!-- Per Page -->
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pr-2">
          <label>{{ $t('action.show') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>{{ $tc('pagination.entries', perPage) }}</label>
        </b-col>
        <b-col cols="12" md="6">
          <label>{{ $t('action.search') }}</label>
          <b-form-input v-model="searchQuery" class="d-inline-block" debounce="500" :placeholder="$t('action.search')" />
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refTranslationsApiTable"
      responsive
      class="position-relative"
      :items="apiTranslations"
      :fields="fields"
      show-empty
      :empty-text="$t('common.no_record_found')"
    >
      <template #cell(translation)="data">
        <span>{{ Object.keys(data.item)[0] }}</span>
      </template>
      <template #cell()="data">
        <b-form-input v-if="data.item[Object.keys(data.item)].isEdit" v-model="data.item[Object.keys(data.item)].translations[data.field.key]" type="text" />
        <span v-else-if="data.item[Object.keys(data.item)].translations[data.field.key]"
          >{{ data.item[Object.keys(data.item)].translations[data.field.key] }}
        </span>

        <span v-else class="no_translation"> {{ $t('translation.undefined') }} </span>
      </template>
      <template #cell(actions)="data" style="min-width: 10rem">
        <!--EDIT-->
        <b-button
          v-if="!data.item[Object.keys(data.item)].isEdit && !isUploading(data.index)"
          v-ripple.400
          :disabled="!$can('OPERATOR_TRANSLATION_EDIT')"
          variant="flat-primary"
          class="btn-icon mr-50"
          size="sm"
          @click="editRowHandler(data, false)"
        >
          <feather-icon v-b-tooltip.hover.bottom="$t('action.edit')" icon="Edit2Icon" size="20" />
        </b-button>
        <b-button-group v-else>
          <b-button v-if="isUploading(data.index)" variant="flat-primary">
            <b-spinner small />
          </b-button>
          <b-button v-else variant="flat-primary" class="btn-icon mr-50" :disabled="isUploading(data.index)" @click="editRowHandler(data, true)">
            <font-awesome-icon v-b-tooltip.hover.bottom="$t('action.save')" class="mr-20 fa-lg" icon="save" />
          </b-button>
          <b-button v-ripple.400 type="button" variant="flat-secondary" class="btn-icon mr-50" :disabled="isUploading(data.index)" @click="cancelEdit(data)">
            <font-awesome-icon v-b-tooltip.hover.bottom="$t('action.cancel')" icon="ban" class="mr-20 fa-lg" />
          </b-button>
        </b-button-group>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">
            {{ dataMeta.from }} {{ $t('pagination.entries_to') }} {{ dataMeta.to }} {{ $t('pagination.entries_of') }} {{ dataMeta.of }}
            {{ $tc('pagination.entries', dataMeta.of) }}
          </span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalApiTranslations"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { fetchApiTranslationsRequest, patchTranslationForManyLocalesRequest } from '@/request/globalApi/requests/translationRequests'

export default {
  name: 'TranslationsApiTable',

  data() {
    return {
      activeLanguages: [],
      defaultLanguage: 'fr',
      apiTranslations: [],
      totalApiTranslations: null,
      fields: [],
      defaultLanguageColumn: [],
      languagesColumns: [],
      perPageOptions: [5, 10, 25, 50, 100],
      perPage: 5,
      currentPage: 1,
      searchQuery: null,
      uploadingIndexes: [],
      editingTranslations: [],
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.$refs.refTranslationsApiTable ? this.$refs.refTranslationsApiTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalApiTranslations,
      }
    },
    allFilters() {
      return {
        page: this.currentPage,
        numberOfItemsPerPage: this.perPage,
        searchQuery: this.searchQuery,
      }
    },
    currentLang() {
      return this.$store.state.appConfig.lang
    },
  },
  watch: {
    allFilters() {
      this.fetchTranslations()
    },
    currentLang() {
      this.setFields()
    },
  },
  mounted() {
    this.fetchActiveLanguages(true)
  },
  methods: {
    fetchActiveLanguages(fetchTranslations) {
      this.$store
        .dispatch('enum/fetchEnum', 'LangCode')
        .then(response => {
          this.activeLanguages = response.data
          this.setFields(fetchTranslations)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('api.alert.aircraft.cannot_fetch'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    setFields(fetchTranslations) {
      const firstColumn = [
        {
          key: 'translation',
          label: this.$t('translation.key'),
          tdClass: 'actionCol',
        },
      ]
      const editColumn = [
        {
          key: 'actions',
          label: this.$tc('action.title', 2),
        },
      ]
      Object.keys(this.activeLanguages).forEach(language => {
        if (this.activeLanguages[language] === this.defaultLanguage) {
          this.defaultLanguageColumn = [
            {
              key: this.activeLanguages[language],
              label: language,
              stickyColumn: true,
            },
          ]
        } else {
          this.languagesColumns.push({
            key: this.activeLanguages[language],
            label: language,
          })
        }
      })
      this.fields = [...firstColumn, ...this.defaultLanguageColumn, ...this.languagesColumns, ...editColumn]
      if (fetchTranslations) {
        this.fetchTranslations()
      }
    },
    fetchTranslations() {
      fetchApiTranslationsRequest(this.allFilters).then(response => {
        const { translations, totalItems } = response.data
        this.totalApiTranslations = totalItems
        this.apiTranslations = Object.keys(translations).map(key => ({ [key]: { translations: translations[key].translations, isEdit: false } }))
      })
    },
    editRowHandler(data, toEdit) {
      if (toEdit === true) {
        this.uploadingIndexes.push(data.index)
        if (this.apiTranslations[data.index][Object.keys(data.item)].isEdit) {
          const translation = {
            key: Object.keys(data.item)[0],
            translations: data.item[Object.keys(data.item)[0]].translations,
          }
          patchTranslationForManyLocalesRequest(translation).then(() => {
            const index = this.uploadingIndexes.indexOf(data.index)
            if (index > -1) {
              this.uploadingIndexes.splice(index, 1)
            }
            this.apiTranslations[data.index][Object.keys(data.item)].isEdit = !this.apiTranslations[data.index][Object.keys(data.item)].isEdit
          })
        }
      } else {
        this.editingTranslations[Object.keys(data.item)[0]] = {
          isEdit: data.item[Object.keys(data.item)].isEdit,
          translations: JSON.parse(JSON.stringify(data.item[Object.keys(data.item)].translations)),
        }
        this.apiTranslations[data.index][Object.keys(data.item)].isEdit = !this.apiTranslations[data.index][Object.keys(data.item)].isEdit
      }
    },
    isUploading(index) {
      return this.uploadingIndexes.indexOf(index) > -1
    },
    cancelEdit(data) {
      const key = Object.keys(data.item)[0]
      const originalTranslation = this.editingTranslations[key]
      this.apiTranslations[data.index][Object.keys(data.item)].translations = originalTranslation.translations
      delete this.editingTranslations[key]
      this.apiTranslations[data.index][Object.keys(data.item)].isEdit = !this.apiTranslations[data.index][Object.keys(data.item)].isEdit
    },
  },
}
</script>

<style>
.no-translation {
  font-size: 0.8rem;
  font-style: italic;
}
</style>
